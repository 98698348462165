import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const MembersNomura = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Engineer：野村｜メンバー紹介｜About us｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-aboutusPage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10">
            About us
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <Link to="/aboutus-data" className=''>
                データで見るSUP
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-environment" className=''>
                働く環境
              </Link>
            </li>
            <li className='mb-5'>
              <Link to="/aboutus-members" className=''>
                メンバー紹介
              </Link>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

            <section id="memberProfile" className="mb-20">
              <div className="flex flex-wrap justify-center sm:justify-start">

                <div className="flex w-full md:w-1/2 p-3">
                  <div className="w-1/3">
                    <StaticImage src="../../images/kantoku.png" className="w-full h-auto max-w-[160px]"/>
                  </div>
                  <div className="w-2/3 px-4">
                    <h3 className=" pl-2 my-3 border-l-4 border-key-sand">
                      開発チーム<br />エンジニア
                    </h3>
                    <h2 className="text-xl pl-3 mb-3 font-medium">野村</h2>
                    <p className="text-xs pl-3">入社：2016年7月<br />出身：福岡県</p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      現在の仕事内容は？
                    </p>
                    <p className="p-2">
                    主に客先でビッグデータETLシステムの設計・開発・保守・運用をしています。担当しているところは、システムの管理Webサイトのフロント・バックエンド、バッチプログラムになります。あと、そのシステムはクラウドで動いているんですが、クラウドのインフラ設計・構築・運用も担当しています。なので、ほぼ一人でそのシステムを見ている感じですね。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      印象に残った仕事は？
                    </p>
                    <p className="p-2">
                    先程話しましたETLシステムの初期開発フェーズですね。設計から開発までほとんど自分でやったのでとても印象に残っています。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事のやりがいを感じるときは？
                    </p>
                    <p className="p-2">
                    やっぱり自分で作ったシステムが動いたときですかね。特に大きなシステム、というより苦労した分だけ、それが大きくなる感じです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      職場の雰囲気は？
                    </p>
                    <p className="p-2">
                    月１回帰社しているので、帰社時の雰囲気をお話します。基本的に黙々と作業してることが多いと思います。時々雑談する感じですかね。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      スタートアッププラスの良いところは？
                    </p>
                    <p className="p-2">
                    フロントからバックエンドの設計や開発、クラウドを使用する場合はクラウドの設計、構築を一人で担当することが多いので、そこらへんを全てやってみたい人にとっては魅力的に感じるかと思います。                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      前職について教えてください。
                    </p>
                    <p className="p-2">
                    電力会社で配電設備の設計業務やってました。ITとは無縁な部門で働いてましたね。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社を決めた要因を教えてください。
                    </p>
                    <p className="p-2">
                    入社当時と今は少し状況が変わっているのですが、当時は派遣ではなく受託開発や自社サービスを行っていること、あと自由な印象を受けた、から入社を決めた感じですね。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      入社して自分が一番成長したところは？
                    </p>
                    <p className="p-2">
                    一つのシステムを一人で設計から開発まで出来るようになったところです。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事でこれからチャレンジしてみたいことは？
                    </p>
                    <p className="p-2">
                    クラウドをもっと有効的かつ効率的に使いたいのでクラウド、特にAWSについて詳しくなりたいので、今AWS認定試験の勉強をしています。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      印象に残っている出来事はありますか？
                    </p>
                    <p className="p-2">
                    社員旅行で海外に行った事が印象に残ってますね、前職は社員旅行がなかったので尚更です。
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      仕事とプライベートの両立はできていますか？
                    </p>
                    <p className="p-2">
                    YES!
                    </p>
                  </div>
                </div>

                <div className=" w-full md:w-1/2 p-3">
                  <div className="w-full h-full p-3 border border-key-sand rounded-xl">
                    <p className="pb-2 list-none flex items-center border-b border-dashed border-key-sand">
                      <StaticImage src="../../images/FAQicon.png" className=" w-[30px] h-auto mr-3"/>
                      「あってよかった」と感じる社内制度・福利厚生は？
                    </p>
                    <p className="p-2">
                    基本的な始業時間が11時と遅いこと、あと事前に連絡さえすれば始業時間を自分で調整ができるところですね。
                    </p>
                  </div>
                </div>

              </div>

            </section>

            <Link to="/aboutus-members" class=" m-5 flex justify-center border border-neutral-800 px-4 py-3 transition duration-300 ease-in-out hover:bg-key-sand">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
              </svg>
              <span className=" ">メンバーの一覧に戻る</span>
            </Link>

          </div>
        </div>
      </div>

    </Layout>

    
  )
}



export default MembersNomura

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

